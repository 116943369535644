import React from "react";
import MaskedInput from "react-text-mask";

// IBANInput bileşeni
const IBANInput = React.forwardRef(function IBANInput(props, ref) {
  const { ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref}
      mask={[
        "T",
        "R",
        /[0-9]/,
        /[0-9]/,
        " ",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        " ",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        " ",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        " ",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        " ",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        " ",
        /[0-9]/,
        /[0-9]/,
      ]}
      placeholderChar={"_"}
      guide={false}
      placeholder={props.placeholder}
      style={{
        width: "100%",
        padding: "15px 14px",
        fontSize: "1rem",
        borderRadius: "4px",
        border: "1px solid rgba(0, 0, 0, 0.23)",
      }}
    />
  );
});

export default IBANInput;
