import axios from "axios";
import { submitForm, searchDocument, fetchContract } from "../api";

export const handleFormSubmit = async (
  e,
  sigCanvas,
  formData,
  setFormData,
  setValidationErrors,
  agreementContent,
  hash,
  verifyId,
  ipAddress,
  userAgent,
  verificationCode
) => {
  e.preventDefault();

  if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
    const signatureDataUrl = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setFormData((prevState) => ({
      ...prevState,
      signature: signatureDataUrl,
    }));
  }

  const properties = formData.fetchDataWithHash.homes.map((home, index) => ({
    propertyName: formData[`propertyName-${index}`],
    propertyLink: formData[`propertyLink-${index}`],
    documentNumber: formData[`documentNumber-${index}`],
    whatsAppGroup: formData[`whatsappGroupName-${index}`],
    personCount: formData[`personCount-${index}`],
    bathRoom: formData[`bathRoom-${index}`],
    checkInTime: formData[`checkInTime-${index}`],
    checkOutTime: formData[`checkOutTime-${index}`],
    location: formData[`location-${index}`],
    address: formData[`address-${index}`],
    bedCount: formData[`bedCount-${index}`],
  }));

  const data = {
    hash,
    verificationId: verifyId,
    landlordFirstName: formData.landlordFirstName,
    landlordLastName: formData.landlordLastName,
    landlordEmail: formData.landlordEmail,
    landlordPhone: formData.landlordPhone,
    properties,
    agree: formData.agree,
    signature: formData.signature,
    ipAddress,
    userAgent,
    dateTime: new Date().toISOString(),
    agreementDocument: "", // Sözleşme içeriği gönderiliyor
    IBAN: formData.IBAN,
    receiverName: formData.receiverName,
    billingType: formData.billingType,
    billingName: formData.billingName,
    taxNumber: formData.taxNumber,
    taxOffice: formData.taxOffice,
    billingAddress: formData.billingAddress,
    billingPhone: formData.billingPhone,
    billingEmail: formData.billingEmail,
    tcIdentityNumber: formData.tcIdentityNumber,
  };

  try {
    const response = await submitForm(data);
    return response;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      setValidationErrors(error.response.data.errors);
      throw error;
    } else {
      console.error("Form submission error", error);
      throw error;
    }
  }
};

export const handleFetchTermsContent = async (
  agreementContent,
  formData,
  setTermsContent,
  setShowTermsModal,
  verificationId
) => {
  try {
    const content = await fetchContract(
      agreementContent,
      formData,
      verificationId
    );
    setTermsContent(content.contract);
    setShowTermsModal(true);
  } catch (error) {
    console.error("Error fetching contract content", error);
  }
};

export const handleSearch = async (
  index,
  formData,
  setButtonLoading,
  setSearchResults,
  setErrorMessages,
  setFormData
) => {
  setButtonLoading((prev) => ({ ...prev, [index]: true }));
  try {
    const response = await searchDocument(formData[`documentNumber-${index}`]);
    if (response.data.Adres) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [`address-${index}`]: response.data.Adres,
        [`bedCount-${index}`]: response.data.YatakSayi,
      }));
      setSearchResults((prevResults) => ({
        ...prevResults,
        [index]: response.data,
      }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        [index]: null,
      }));
    } else if (response.data.message) {
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        [index]: response.data.message,
      }));
      setSearchResults((prevResults) => ({
        ...prevResults,
        [`address-${index}`]: null,
        [`bedCount-${index}`]: null,
        [index]: null,
      }));
    } else if (response.status === 204) {
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        [index]: "Belge bulunamadı",
      }));
      setSearchResults((prevResults) => ({
        ...prevResults,
        [`address-${index}`]: null,
        [`bedCount-${index}`]: null,
        [index]: null,
      }));
    }
  } catch (error) {
    console.error("Error searching document", error);
    setErrorMessages((prevMessages) => ({
      ...prevMessages,
      [index]: "Belge bulunamadı",
    }));
  }
  setButtonLoading((prev) => ({ ...prev, [index]: false }));
};

export const handleEnd = (sigCanvas, setIsSignatureEmpty) => {
  setIsSignatureEmpty(sigCanvas.current.isEmpty());
};

export const clearSignature = (
  sigCanvas,
  setIsSignatureEmpty,
  setShowSignaturePad,
  setFormData,
  setSignatureImgSrc
) => {
  sigCanvas.current.clear();
  setIsSignatureEmpty(true);
  setShowSignaturePad(false);
  setFormData((prevState) => ({
    ...prevState,
    agree: false,
    signature: "",
  }));
  setSignatureImgSrc("");
};

export const handleConfirmSignature = (
  sigCanvas,
  setFormData,
  setSignatureImgSrc,
  setShowSignaturePad
) => {
  if (sigCanvas.current.isEmpty()) {
    setFormData((prevState) => ({
      ...prevState,
      agree: false,
      signature: "",
    }));
  } else {
    const signatureDataUrl = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setFormData((prevState) => ({
      ...prevState,
      signature: signatureDataUrl,
    }));
    setSignatureImgSrc(signatureDataUrl);
  }
  setShowSignaturePad(false);
};

export const handleChange = (e, setFormData, setShowSignaturePad) => {
  const { name, value, type, checked } = e.target;
  if (name === "agree" && checked) {
    setShowSignaturePad(true);
  }
  setFormData((prevState) => ({
    ...prevState,
    [name]: type === "checkbox" ? checked : value,
  }));
};
