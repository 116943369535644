import React, { useState } from "react";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import config from "../config";

const PhoneNumberInput = ({ hash, onPhoneSubmit, onSetRemainingTime }) => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setError("");
    setLoading(true);

    axios
      .get(
        `${config.API_BASE_URL}/api/Verification/isCodeExpired?hash=${hash}&phoneNumber=${phone}`
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.remainingTime > 0) {
            // Kod süresi dolmamış, kalan süreyi ayarla ve SMS doğrulama adımına geç
            onSetRemainingTime(response.data.remainingTime);
            onPhoneSubmit(phone, false);
          } else {
            // Kod süresi dolmuş, yeni kod oluştur
            axios
              .post(`${config.API_BASE_URL}/api/Verification/generate`, {
                hash,
                phoneNumber: phone,
              })
              .then((response) => {
                if (response.status === 200) {
                  onPhoneSubmit(phone, true);
                } else {
                  setError("Kod oluşturulamadı, lütfen tekrar deneyin.");
                }
              })
              .catch((error) => {
                console.error("API hatası:", error);
                setError("Kod oluşturulamadı, lütfen tekrar deneyin.");
              })
              .finally(() => {
                setLoading(false);
              });
          }
        } else {
          setError("Geçersiz veri");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("API hatası:", error);
        setError("Geçersiz veri");
        setLoading(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    setError("");
  };

  const isPhoneNumberValid = (value) => {
    const phoneNumber = value.replace(/[^0-9]/g, ""); // Sadece rakamları al
    // Eğer sadece ülke kodu varsa geçerli kabul etme
    if (phoneNumber === "90") {
      return true;
    }
    // Türkiye telefon numarası kontrolü
    if (phoneNumber.startsWith("90") && phoneNumber.length !== 12) {
      return "Geçersiz telefon numarası";
    }
    // Eğer geçersiz numara girilmişse
    if (phoneNumber.length < 10 || phoneNumber.length > 15) {
      return "Geçersiz telefon numarası";
    }
    return true;
  };

  const isPhoneValid = isPhoneNumberValid(phone) === true;

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
      <Typography variant="h6">Telefon Numarası Girin</Typography>
      <Typography>Lütfen sistemde kayıtlı telefon numaranızı girin.</Typography>
      <PhoneInput
        country={"tr"}
        value={phone}
        onChange={handlePhoneChange}
        onKeyDown={handleKeyPress}
        isValid={isPhoneNumberValid}
        inputProps={{
          name: "phone",
          required: true,
          autoFocus: true,
        }}
        inputStyle={{
          width: "100%",
          marginTop: "24px",
          height: "40px",
          fontSize: "16px",
          paddingLeft: "52px",
        }}
        buttonStyle={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          height: "100%",
        }}
        containerStyle={{
          width: "auto",
          position: "relative",
          marginTop: "44px",
        }}
      />

      <Button
        onClick={handleSubmit}
        color="primary"
        variant="contained"
        sx={{ mt: 2, position: "relative" }}
        disabled={loading || !isPhoneValid} // Geçersiz numara girildiğinde butonu devre dışı bırak
      >
        {loading ? (
          <CircularProgress size={24} sx={{ color: "white" }} />
        ) : (
          "Devam Et"
        )}
      </Button>
    </Box>
  );
};

export default PhoneNumberInput;
