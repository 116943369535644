import React, { useState, useEffect, useRef } from "react";
import MaskedInput from "react-text-mask";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Container,
  CircularProgress,
  Alert,
  Modal,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TermsModal from "./TermsModal";
import LandlordInfo from "./LandlordInfo";
import PropertyInfo from "./PropertyInfo";
import SignatureSection from "./SignatureSection";
import {
  fetchDataWithHash,
  submitForm,
  fetchTermsContent,
  searchDocument,
  fetchIpAddress,
} from "../api";
import {
  handleFormSubmit,
  handleFetchTermsContent,
  handleSearch,
  handleEnd,
  clearSignature,
  handleConfirmSignature,
  handleChange,
} from "./formHandlers";
import IBANInput from "./IBANInput"; // Import IBANInput component

const Form = ({ hash, verifyId, setStep, refreshForms }) => {
  const [formData, setFormData] = useState({
    landlordFirstName: "",
    landlordLastName: "",
    landlordEmail: "",
    landlordPhone: "",
    propertyName: "",
    propertyLink: "",
    whatsappGroupName: "",
    documentNumber: "",
    agree: false,
    signature: "",
    IBAN: "",
    receiverName: "",
    billingType: 0,
    billingName: "",
    taxNumber: "",
    taxOffice: "",
    tcIdentityNumber: "",
    billingAddress: "",
    billingPhone: "",
    billingEmail: "",
  });

  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [buttonLoading, setButtonLoading] = useState({});
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [ipAddress, setIpAddress] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [termsContent, setTermsContent] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [signatureImgSrc, setSignatureImgSrc] = useState("");
  const [agreementContent, setAgreementContent] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [showBillingInfo, setShowBillingInfo] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationPhoneNumber, setVerificationPhoneNumber] = useState("");
  const sigCanvas = useRef(null);
  const errorRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await fetchIpAddress();
        setIpAddress(response.data.ipAddress);
      } catch (error) {
        console.error("Error fetching IP address", error);
      }
    };

    const currentDateTime = new Date().toLocaleString();
    setDateTime(currentDateTime);

    fetchIp();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchDataWithHash(verifyId, hash);
        const data = JSON.parse(response.data.data);

        if (data.status === false) {
          setError(data.message);
        } else {
          setFormData((prevState) => ({
            ...prevState,
            landlordFirstName: data.evsahibi.name,
            landlordLastName: data.evsahibi.surname,
            landlordEmail: data.evsahibi.email,
            landlordPhone: `+90${data.evsahibi.phone.slice(-10)}`,
            fetchDataWithHash: data,
          }));
          setPhoneNumber(response?.data?.phoneNumber);
          setVerificationPhoneNumber(response?.data?.verificationPhoneNumber);
          setVerificationCode(response?.data?.verificationCode);
          setAgreementContent(response?.data?.agreementContent || "");
          setLoading(false);
        }
      } catch (err) {
        setError("Veri çekme hatası");
        setLoading(false);
      }
      setFetching(false);
    };

    fetchData();
  }, [hash, verifyId]);

  useEffect(() => {
    if (formData?.fetchDataWithHash) {
      formData.fetchDataWithHash.homes?.forEach((home, index) => {
        setFormData((prevState) => ({
          ...prevState,
          [`propertyName-${index}`]: home?.title,
          [`propertyLink-${index}`]: home?.url,
          [`whatsappGroupName-${index}`]: home?.whatsapp,
          [`personCount-${index}`]: home?.kisi,
          [`bathRoom-${index}`]: home?.banyo,
          [`checkInTime-${index}`]: home?.giris_saat,
          [`checkOutTime-${index}`]: home?.cikis_saat,
          [`location-${index}`]: home?.enlem + "," + home?.boylam,
          [`address-${index}`]: "",
          [`bedCount-${index}`]: "",
        }));
      });
    }
  }, [formData?.fetchDataWithHash]);

  useEffect(() => {
    if (formData.billingType === 0) {
      setFormData((prevState) => ({
        ...prevState,
        taxNumber: "",
        taxOffice: "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        tcIdentityNumber: "",
      }));
    }
  }, [formData.billingType]);

  const getUserAgent = () => {
    return navigator.userAgent;
  };

  const validateIBAN = (iban) => {
    // Türkiye IBAN'ları için geçerli kontrol
    const ibanRegex = /^TR\d{2}\d{4}\d{4}\d{4}\d{4}\d{4}\d{2}$/;
    const strippedIban = iban.replace(/\s+/g, ""); // Boşlukları kaldır
    return ibanRegex.test(strippedIban);
  };

  const handleFormSubmitWrapper = async (e) => {
    e.preventDefault();
    setValidationErrors(null);
    const errors = {};

    // IBAN validation
    if (!validateIBAN(formData.IBAN)) {
      errors.IBAN = "Geçersiz IBAN formatı. Lütfen doğru formatta giriniz.";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    setIsSubmitting(true); // Submit işlemi başladığında loading spinner'ı göster

    try {
      const response = await handleFormSubmit(
        e,
        sigCanvas,
        { ...formData, IBAN: formData.IBAN.replace(/\s+/g, "") },
        setFormData,
        setValidationErrors,
        agreementContent,
        hash,
        verifyId,
        ipAddress,
        getUserAgent(),
        verificationCode
      );
      setSuccessMessage(response.data.message);
      refreshForms();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setValidationErrors(error.response.data.errors);
        if (errorRef.current) {
          errorRef.current.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        console.error("Form submission error", error);
      }
    } finally {
      setIsSubmitting(false); // Submit işlemi bittiğinde loading spinner'ı kaldır
    }
  };

  const handleBillingCheckboxChange = (event) => {
    setShowBillingInfo(event.target.checked);
  };

  const billingNamePlaceholder =
    formData.billingType === 0 ? "Ad Soyad" : "Ünvan";

  const handleTermsClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSignatureEmpty) {
      setValidationErrors({
        ...validationErrors,
        signature:
          "Sözleşme şartlarını görmek için imza atmanız gerekmektedir.",
      });
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }

    handleFetchTermsContent(
      agreementContent,
      formData,
      setTermsContent,
      setShowTermsModal,
      verifyId
    );
  };

  if (fetching) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ padding: 2 }}>
      <TermsModal
        open={showTermsModal}
        onClose={() => setShowTermsModal(false)}
        termsContent={termsContent}
      >
        <Box
          sx={{
            position: "fixed",
            top: 8,
            right: 8,
          }}
        >
          <IconButton
            color="inherit"
            onClick={() => setShowTermsModal(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </TermsModal>
      <Modal
        open={!!successMessage}
        onClose={() => {
          setSuccessMessage(null);
          setStep(3);
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              padding: 2,
              borderRadius: 1,
              maxWidth: "400px",
              width: "100%",
              textAlign: "center",
              boxShadow: 3,
            }}
          >
            <Typography variant="h6">Başarılı!</Typography>
            <Typography>{successMessage}</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSuccessMessage(null);
                setStep(3);
              }}
              sx={{ mt: 2 }}
            >
              Tamam
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          my: 3,
          backgroundColor: "white",
          p: 3,
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        {error ? (
          <>
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setStep(3)}
              sx={{ mr: 2, bgcolor: "red" }}
            >
              Geri Dön
            </Button>
          </>
        ) : (
          <form onSubmit={handleFormSubmitWrapper}>
            <Box
              sx={{
                mb: 3,
                backgroundColor: "white",
                p: 3,
                borderRadius: 1,
                boxShadow: 1,
              }}
            >
              <Typography variant="h6" gutterBottom className="my-3 fw-bold">
                Ev Sahibinin
              </Typography>
              <LandlordInfo
                formData={formData}
                handleChange={(e) =>
                  handleChange(e, setFormData, setShowSignaturePad)
                }
              />
              <Typography
                variant="body2"
                color="error"
                sx={{ mt: 2, fontWeight: "bold", textAlign: "center" }}
              >
                Kiralama yapılacak ilan sahibine ait değilse, lütfen bizimle
                iletişime geçiniz. {phoneNumber}
              </Typography>
            </Box>
            <Box
              sx={{
                mb: 3,
                backgroundColor: "white",
                p: 3,
                borderRadius: 1,
                boxShadow: 1,
              }}
            >
              <Typography variant="h6" gutterBottom className="my-3 fw-bold">
                Banka Bilgileri
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <IBANInput
                    id="IBAN"
                    name="IBAN"
                    value={formData.IBAN}
                    placeholder="TR00 0000 0000 0000 0000 0000 00"
                    onChange={(e) =>
                      handleChange(e, setFormData, setShowSignaturePad)
                    }
                  />
                  {validationErrors?.IBAN && (
                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                      {validationErrors.IBAN}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Alıcı Adı"
                    name="receiverName"
                    variant="outlined"
                    fullWidth
                    value={formData.receiverName}
                    onChange={(e) =>
                      handleChange(e, setFormData, setShowSignaturePad)
                    }
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                mb: 3,
                backgroundColor: "white",
                p: 3,
                borderRadius: 1,
                boxShadow: 1,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showBillingInfo}
                    onChange={handleBillingCheckboxChange}
                    name="showBillingInfo"
                    color="primary"
                  />
                }
                label="Fatura Bilgilerimi Girmek İstiyorum"
                sx={{ width: "100%", display: "flex", marginTop: 3 }}
              />
              {showBillingInfo && (
                <>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className="my-3 fw-bold"
                  >
                    Fatura Bilgileri
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Fatura Tipi"
                        name="billingType"
                        variant="outlined"
                        fullWidth
                        select
                        SelectProps={{
                          native: true,
                        }}
                        value={formData.billingType}
                        onChange={(e) =>
                          handleChange(e, setFormData, setShowSignaturePad)
                        }
                      >
                        <option value={0}>Bireysel</option>
                        <option value={1}>Kurumsal</option>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label={billingNamePlaceholder}
                        name="billingName"
                        variant="outlined"
                        fullWidth
                        value={formData.billingName}
                        onChange={(e) =>
                          handleChange(e, setFormData, setShowSignaturePad)
                        }
                      />
                    </Grid>
                    {formData.billingType == 0 ? (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="TC Kimlik Numarası"
                          name="tcIdentityNumber"
                          variant="outlined"
                          fullWidth
                          value={formData.tcIdentityNumber}
                          onChange={(e) =>
                            handleChange(e, setFormData, setShowSignaturePad)
                          }
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Vergi Numarası"
                            name="taxNumber"
                            variant="outlined"
                            fullWidth
                            value={formData.taxNumber}
                            onChange={(e) =>
                              handleChange(e, setFormData, setShowSignaturePad)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Vergi Dairesi"
                            name="taxOffice"
                            variant="outlined"
                            fullWidth
                            value={formData.taxOffice}
                            onChange={(e) =>
                              handleChange(e, setFormData, setShowSignaturePad)
                            }
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        label="Fatura Adresi"
                        name="billingAddress"
                        variant="outlined"
                        fullWidth
                        value={formData.billingAddress}
                        onChange={(e) =>
                          handleChange(e, setFormData, setShowSignaturePad)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Fatura Telefonu"
                        name="billingPhone"
                        variant="outlined"
                        fullWidth
                        value={formData.billingPhone}
                        onChange={(e) =>
                          handleChange(e, setFormData, setShowSignaturePad)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Fatura Email"
                        name="billingEmail"
                        variant="outlined"
                        fullWidth
                        value={formData.billingEmail}
                        onChange={(e) =>
                          handleChange(e, setFormData, setShowSignaturePad)
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
            <Box
              sx={{
                mb: 3,
                backgroundColor: "white",
                p: 3,
                borderRadius: 1,
                boxShadow: 1,
              }}
            >
              <Typography variant="h6" gutterBottom className="my-3 fw-bold">
                Kiralama Yapılacak İlanlar
              </Typography>
              {formData.fetchDataWithHash?.homes &&
                formData.fetchDataWithHash?.homes.map((home, index) => (
                  <PropertyInfo
                    key={index}
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={(e) =>
                      handleChange(e, setFormData, setShowSignaturePad)
                    }
                    handleSearch={(index) =>
                      handleSearch(
                        index,
                        formData,
                        setButtonLoading,
                        setSearchResults,
                        setErrorMessages,
                        setFormData
                      )
                    }
                    searchResults={searchResults}
                    errorMessages={errorMessages}
                    index={index}
                    buttonLoading={buttonLoading[index] || false}
                  />
                ))}
            </Box>
            <SignatureSection
              showSignaturePad={showSignaturePad}
              sigCanvas={sigCanvas}
              isSignatureEmpty={isSignatureEmpty}
              clearSignature={() =>
                clearSignature(
                  sigCanvas,
                  setIsSignatureEmpty,
                  setShowSignaturePad,
                  setFormData,
                  setSignatureImgSrc
                )
              }
              handleConfirmSignature={() =>
                handleConfirmSignature(
                  sigCanvas,
                  setFormData,
                  setSignatureImgSrc,
                  setShowSignaturePad
                )
              }
              handleEnd={() => handleEnd(sigCanvas, setIsSignatureEmpty)}
              signatureImgSrc={signatureImgSrc}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.agree}
                  onChange={(e) =>
                    handleChange(e, setFormData, setShowSignaturePad)
                  }
                  name="agree"
                  color="primary"
                />
              }
              label={
                <Box sx={{ textAlign: "center", width: "100%" }}>
                  <Typography component="div" sx={{ display: "block" }}>
                    <a href="#" onClick={handleTermsClick}>
                      Sözleşme Şartları
                    </a>
                  </Typography>
                  <Typography component="span" sx={{ display: "block" }}>
                    Yukarıdaki bilgilerin doğruluğunu <b>{ipAddress}</b> ip
                    adresi üzerinden, <br /> <b>{verificationPhoneNumber}</b>{" "}
                    numaralı telefona gönderilen <b>{verificationCode}</b>{" "}
                    doğrulama kodu ile <br />
                    <b>{dateTime}</b> tarih/saatinde onayladığımı kabul
                    ediyorum.
                  </Typography>
                </Box>
              }
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: 3,
              }}
            />
            <Box mt={2} textAlign="center">
              {validationErrors && (
                <Alert severity="error" sx={{ mb: 2 }} ref={errorRef}>
                  {Array.isArray(validationErrors)
                    ? validationErrors.map((error, index) => (
                        <div key={index}>{error}</div>
                      ))
                    : Object.entries(validationErrors).map(
                        ([field, errors]) => (
                          <div key={field}>
                            {field}:{" "}
                            {Array.isArray(errors) ? errors.join(", ") : errors}
                          </div>
                        )
                      )}
                </Alert>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setStep(3)}
                sx={{ mr: 2, bgcolor: "red" }}
              >
                Geri Dön
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting} // Butonu submit işlemi sırasında devre dışı bırak
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Formu Gönder"}{" "}
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </Container>
  );
};

export default Form;
