import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
} from "@mui/material";

const FormList = ({ formsList, setStep, phone }) => {
  const [open, setOpen] = useState(false);
  const [agreementContent, setAgreementContent] = useState("");

  const handleOpen = (content) => {
    setAgreementContent(content);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAgreementContent("");
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("tr-TR", options);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Hoşgeldiniz, {phone}
      </Typography>
      <Typography variant="h6" gutterBottom className="my-3 fw-bold">
        Daha Önce Oluşturulmuş Formlar
      </Typography>
      {formsList?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography>Hiç form bulunamadı.</Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: "#f2f2f2" }}>
                  <TableCell style={{ fontWeight: "bold" }}>Ad Soyad</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Telefon</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    IP Adresi
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Onay Tarihi
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Sözleşme</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formsList.map((form) => (
                  <TableRow key={form.id}>
                    <TableCell>{`${form.landlordFirstName} ${form.landlordLastName}`}</TableCell>
                    <TableCell>{form.landlordEmail}</TableCell>
                    <TableCell>{form.landlordPhone}</TableCell>
                    <TableCell>{form.ipAddress}</TableCell>
                    <TableCell>{formatDate(form.dateTime)}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpen(form.agreementDocument)}
                      >
                        Görüntüle
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setStep(4)}
        sx={{ mt: 2 }}
      >
        Yeni Form Oluştur
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Sözleşme İçeriği
          </Typography>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: agreementContent }}
          />
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Kapat
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default FormList;
