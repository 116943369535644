import React from "react";
import MaskedInput from "react-text-mask";

// DocumentNumberInput bileşeni
const DocumentNumberInput = React.forwardRef(function DocumentNumberInput(
  props,
  ref
) {
  const { ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={ref}
      mask={(value) => {
        if (!value || value.length < 2) {
          return [/[0-9]/, /[0-9]/, "-"];
        }
        const numbers = value.replace(/[^0-9]/g, "");
        return [
          /[0-9]/,
          /[0-9]/,
          "-",
          ...Array(numbers.length - 2).fill(/[0-9]/),
        ];
      }}
      placeholderChar={"_"}
      guide={false}
      placeholder={props.placeholder}
      style={{
        width: "100%",
        padding: "15px 14px",
        fontSize: "1rem",
        borderRadius: "4px",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        background: "none",
      }}
    />
  );
});

export default DocumentNumberInput;
