import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import config from "../config";

const SmsVerification = ({
  hash,
  phone,
  onSmsSubmit,
  onBack,
  initialRemainingTime,
}) => {
  const [smsCode, setSmsCode] = useState("");
  const [open, setOpen] = useState(true);
  const [timer, setTimer] = useState(initialRemainingTime || 120);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setOpen(false);
      onBack();
    }
  }, [timer, onBack]);

  const handleSubmit = () => {
    axios
      .post(`${config.API_BASE_URL}/api/Verification/verify`, {
        hash,
        phoneNumber: phone,
        code: smsCode,
      })
      .then((response) => {
        if (response.status === 200) {
          const verifyId = response.data.verificationId;
          setOpen(false);
          onSmsSubmit(smsCode, verifyId);
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        console.error("API hatası:", error);
        setError(true);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <Dialog open={open}>
      <DialogTitle>SMS Kodunu Girin</DialogTitle>
      <DialogContent>
        <Typography>{phone} numarasına gönderilen SMS kodunu girin.</Typography>
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          label="SMS Kodu"
          value={smsCode}
          onChange={(e) => setSmsCode(e.target.value)}
          onKeyPress={handleKeyPress}
          error={error}
          helperText={error ? "Hatalı SMS kodu, lütfen tekrar deneyin." : ""}
        />
        <Typography>Geçerlilik süresi: {formatTime(timer)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBack} color="secondary">
          Geri
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Devam Et
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SmsVerification;
