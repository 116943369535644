// src/config.js
const dev = {
  API_BASE_URL: "http://localhost:5174",
};

const prod = {
  API_BASE_URL: "https://esign-api.natsisa.com",
};

const config = process.env.NODE_ENV === "development" ? dev : prod;

export default config;
