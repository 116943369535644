import React from "react";
import { Box, Modal, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const TermsModal = ({ open, onClose, termsContent }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            padding: 2,
            borderRadius: 1,
            maxWidth: "800px",
            width: "100%",
            maxHeight: "80vh",
            position: "relative",
          }}
        >
          <Box
            sx={{
              textAlign: "right",
              position: "absolute",
              top: "-40px",
              right: 0,
              background: "white",
            }}
          >
            <Button onClick={onClose} sx={{ minWidth: "auto" }}>
              <CloseIcon />
            </Button>
          </Box>
          <Box
            sx={{
              marginTop: 2,
              overflowY: "auto",
              backgroundColor: "white",
              borderRadius: 1,
              maxWidth: "800px",
              width: "100%",
              maxHeight: "70vh",
              position: "relative",
            }}
            dangerouslySetInnerHTML={{ __html: termsContent }}
          />
          <Box mt={2} textAlign="center">
            <Button variant="contained" color="primary" onClick={onClose}>
              Okudum - Tamam
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsModal;
