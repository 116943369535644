// src/components/SignatureSection.js
import React from "react";
import { Box, Typography, Button } from "@mui/material";
import SignaturePad from "./SignaturePad";

const SignatureSection = ({
  showSignaturePad,
  sigCanvas,
  isSignatureEmpty,
  clearSignature,
  handleConfirmSignature,
  handleEnd,
  signatureImgSrc,
}) => (
  <Box>
    {showSignaturePad && (
      <SignaturePad
        sigCanvas={sigCanvas}
        handleEnd={handleEnd}
        handleConfirmSignature={handleConfirmSignature}
        clearSignature={clearSignature}
        isSignatureEmpty={isSignatureEmpty}
      />
    )}
    {signatureImgSrc && (
      <Box mt={2} textAlign="center">
        <Typography variant="subtitle1">İmzanız:</Typography>
        <img src={signatureImgSrc} alt="Signature" />
      </Box>
    )}
  </Box>
);

export default SignatureSection;
