import axios from "axios";
import config from "./config";

const API_BASE_URL = `${config.API_BASE_URL}/api`;

// API endpoint'lerini tanımla
const fetchDataWithHash = (verificationId, hash) => {
  return axios.get(
    `${API_BASE_URL}/Verification/fetchDataWithHash?verificationId=${verificationId}&hash=${hash}`
  );
};

const submitForm = (data) => {
  return axios.post(`${API_BASE_URL}/Form/submit`, data);
};

const fetchTermsContent = () => {
  return axios.get(`${config.API_BASE_URL}/proxy-terms`);
};

const searchDocument = (documentNumber) => {
  return axios.get(
    `https://belgesorgula.bocek.com.tr/Search?belgeNo=${documentNumber}`
  );
};

const fetchIpAddress = () => {
  return axios.get(`${API_BASE_URL}/IP/get-ip`);
};

const getDataWithHash = (hash, domain) => {
  return axios.get(`${API_BASE_URL}/Hash/get-data/${hash}?domain=${domain}`);
};

const fetchFormsList = (hash, verificationId) => {
  return axios.get(
    `${API_BASE_URL}/Form/list?hash=${hash}&verificationId=${verificationId}`
  );
};

const fetchContract = async (template, formData, verificationId) => {
  const response = await axios.post(`${API_BASE_URL}/Contract/Create`, {
    template: template,
    form: {
      hash: formData.hash,
      verificationId: verificationId, // Burada doğrudan verificationId gönderiyoruz
      landlordFirstName: formData.landlordFirstName,
      landlordLastName: formData.landlordLastName,
      landlordEmail: formData.landlordEmail,
      landlordPhone: formData.landlordPhone,
      properties: formData.fetchDataWithHash.homes.map((home, index) => ({
        propertyName: formData[`propertyName-${index}`],
        propertyLink: formData[`propertyLink-${index}`],
        documentNumber: formData[`documentNumber-${index}`],
        whatsAppGroup: formData[`whatsappGroupName-${index}`],
        personCount: formData[`personCount-${index}`],
        bathRoom: formData[`bathRoom-${index}`],
        checkInTime: formData[`checkInTime-${index}`],
        checkOutTime: formData[`checkOutTime-${index}`],
        location: formData[`location-${index}`],
        address: formData[`address-${index}`],
        bedCount: formData[`bedCount-${index}`],
      })),
      agree: formData.agree,
      signature: formData.signature,
      ipAddress: formData.ipAddress,
      userAgent: navigator.userAgent,
      dateTime: new Date().toISOString(),
      agreementDocument: formData.agreementContent,
      billingType: formData.billingType,
      billingName: formData.billingName,
      taxNumber: formData.taxNumber,
      taxOffice: formData.taxOffice,
      tcIdentityNumber: formData.tcIdentityNumber,
      billingAddress: formData.billingAddress,
      billingPhone: formData.billingPhone,
      billingEmail: formData.billingEmail,
      iban: formData.IBAN,
      receiverName: formData.receiverName,
    },
  });

  return response.data;
};

export {
  fetchDataWithHash,
  submitForm,
  fetchTermsContent,
  searchDocument,
  fetchIpAddress,
  getDataWithHash,
  fetchFormsList,
  fetchContract,
};
