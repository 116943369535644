import React from "react";
import { Grid, TextField } from "@mui/material";

const LandlordInfo = ({ formData, handleChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Ad"
          name="landlordFirstName"
          variant="outlined"
          fullWidth
          value={formData.landlordFirstName}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Soyad"
          name="landlordLastName"
          variant="outlined"
          fullWidth
          value={formData.landlordLastName}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Email"
          name="landlordEmail"
          type="email"
          variant="outlined"
          fullWidth
          value={formData.landlordEmail}
          onChange={handleChange}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Telefon"
          name="landlordPhone"
          variant="outlined"
          fullWidth
          value={formData.landlordPhone}
          InputProps={{
            readOnly: true,
          }}
          required
        />
      </Grid>
    </Grid>
  );
};

export default LandlordInfo;
