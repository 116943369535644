import React from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DocumentNumberInput from "./DocumentNumberInput";

const PropertyInfo = ({
  formData,
  handleChange,
  handleSearch,
  searchResults,
  errorMessages,
  index,
  buttonLoading,
}) => (
  <Box
    key={index}
    sx={{
      border: "1px solid #ccc",
      borderRadius: "8px",
      padding: "16px",
      marginBottom: "16px",
      marginTop: index === 0 ? "16px" : "0",
      backgroundColor: "#f9f9f9", // Yumuşak bir arka plan rengi
    }}
  >
    <Typography variant="h6" gutterBottom className="my-3 fw-bold">
      Kiralama Yapılacak İlanın {index + 1}
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Sitedeki Adı"
          name={`propertyName-${index}`}
          variant="outlined"
          fullWidth
          value={formData[`propertyName-${index}`]}
          InputProps={{
            readOnly: true,
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6} style={{ position: "relative" }}>
        <TextField
          label="Sitedeki Linki"
          name={`propertyLink-${index}`}
          variant="outlined"
          fullWidth
          value={formData[`propertyLink-${index}`]}
          InputProps={{
            readOnly: true,
            style: {
              paddingRight: "40px", // Sağdan padding ekliyoruz
            },
          }}
          disabled
        />
        <Tooltip title="Linke Git">
          <IconButton
            onClick={() =>
              window.open(formData[`propertyLink-${index}`], "_blank")
            }
            sx={{
              position: "absolute",
              right: 8,
              top: "50%",
              transform: "translateY(-50%)",
              marginTop: "8px",
              padding: "5px", // Yukarıdan ve aşağıdan padding ekliyoruz
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Adres"
          name={`address-${index}`}
          focused
          variant="outlined"
          fullWidth
          value={formData[`address-${index}`]}
          InputProps={{
            readOnly: true,
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Konum"
          name={`location-${index}`}
          variant="outlined"
          fullWidth
          value={formData[`location-${index}`]}
          InputProps={{
            readOnly: true,
          }}
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Yatak Sayısı"
          name={`bedCount-${index}`}
          variant="outlined"
          fullWidth
          value={formData[`bedCount-${index}`]}
          InputProps={{
            readOnly: true,
          }}
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Kişi"
          name={`personCount-${index}`}
          variant="outlined"
          fullWidth
          value={formData[`personCount-${index}`]}
          InputProps={{
            readOnly: true,
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Banyo"
          name={`bathRoom-${index}`}
          variant="outlined"
          fullWidth
          value={formData[`bathRoom-${index}`]}
          InputProps={{
            readOnly: true,
          }}
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Giriş Saat"
          name={`checkInTime-${index}`}
          variant="outlined"
          fullWidth
          value={formData[`checkInTime-${index}`]}
          InputProps={{
            readOnly: true,
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Çıkış Saat"
          name={`checkOutTime-${index}`}
          variant="outlined"
          fullWidth
          value={formData[`checkOutTime-${index}`]}
          InputProps={{
            readOnly: true,
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Whatsapp Grup Adı"
          name={`whatsappGroupName-${index}`}
          variant="outlined"
          fullWidth
          value={formData[`whatsappGroupName-${index}`]}
          InputProps={{
            readOnly: true,
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <DocumentNumberInput
          id={`documentNumber-${index}`}
          name={`documentNumber-${index}`}
          value={formData[`documentNumber-${index}`]}
          placeholder="00-000"
          onChange={handleChange}
        />
        {errorMessages[`documentNumber-${index}`] && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {errorMessages[`documentNumber-${index}`]}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleSearch(index)}
          fullWidth
          disabled={buttonLoading}
          sx={{ height: "56px", mt: 0 }}
        >
          {buttonLoading ? "Yükleniyor..." : "Sorgula"}
        </Button>
      </Grid>
    </Grid>
    {errorMessages[index] ? (
      <Alert severity="error" sx={{ marginTop: 2 }}>
        {errorMessages[index]}
      </Alert>
    ) : (
      searchResults[index] && (
        <TableContainer component={Paper} sx={{ marginTop: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: "6px", fontSize: "0.875rem" }}>
                  Belge No
                </TableCell>
                <TableCell sx={{ padding: "6px", fontSize: "0.875rem" }}>
                  Detay
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(searchResults[index]).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell sx={{ padding: "6px", fontSize: "0.875rem" }}>
                    {key}
                  </TableCell>
                  <TableCell sx={{ padding: "6px", fontSize: "0.875rem" }}>
                    {value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    )}
  </Box>
);

export default PropertyInfo;
