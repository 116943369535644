import React from "react";
import { Box, Modal, Paper, Typography, Button } from "@mui/material";
import SignatureCanvas from "react-signature-canvas";

const SignaturePad = ({
  sigCanvas,
  handleEnd,
  handleConfirmSignature,
  clearSignature,
  isSignatureEmpty,
}) => {
  return (
    <Modal open onClose={() => {}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Paper
          sx={{ padding: 2, borderRadius: 1, maxWidth: "90%", width: "400px" }}
        >
          <Typography variant="h6" align="center">
            İmzanızı Ekleyin
          </Typography>
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            onEnd={handleEnd}
            canvasProps={{ width: 350, height: 150, className: "sigCanvas" }}
            className="sigCanvas"
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              variant="contained"
              color="success"
              onClick={handleConfirmSignature}
              disabled={isSignatureEmpty}
            >
              Tamam
            </Button>
            <Button variant="contained" color="error" onClick={clearSignature}>
              İptal
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default SignaturePad;
