import React, { useEffect, useState } from "react";
import { Container, Box, CircularProgress, Alert } from "@mui/material";
import PhoneNumberInput from "./components/PhoneNumberInput";
import SmsVerification from "./components/SmsVerification";
import Form from "./components/Form";
import FormList from "./components/FormList"; // Form listesi bileşeni
import { getDataWithHash, fetchFormsList } from "./api"; // Form listesi için API çağrısı

const App = () => {
  const [step, setStep] = useState(0);
  const [phone, setPhone] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hash, setHash] = useState("");
  const [remainingTime, setRemainingTime] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [verifyId, setVerifyId] = useState(null);
  const [formsList, setFormsList] = useState([]);
  const queryString = window.location.pathname.substring(1);
  const urlParams = new URLSearchParams(window.location.search);
  const referrerDomain = urlParams.get("Domain");

  useEffect(() => {
    if (!queryString || !referrerDomain) {
      setError("Gerekli parametreler eksik");
      setLoading(false);
      return;
    }

    setHash(queryString);

    getDataWithHash(queryString, referrerDomain)
      .then((response) => {
        if (response.status === 200) {
          setResponseData(response.data);
          if (response.data.message === "success") {
            setStep(1);
            setError(null);
          } else {
            setError("Domain uyuşmuyor");
          }
        } else {
          setError("Geçersiz veri");
        }
      })
      .catch((error) => {
        console.error("API hatası:", error);
        setError("Geçersiz veri");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [queryString, referrerDomain]);

  useEffect(() => {
    if (step === 3) {
      refreshForms(); // Form listesini yenile
    }
  }, [step, hash, verifyId]);

  const handlePhoneSubmit = (phone, isNewCode) => {
    setPhone(phone);
    if (isNewCode) {
      setRemainingTime(120);
    }
    setStep(2);
  };

  const handleSetRemainingTime = (time) => {
    setRemainingTime(time);
  };

  const handleSmsSubmit = (smsCode, verifyId) => {
    setSmsCode(smsCode);
    setVerifyId(verifyId);
    setStep(3);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const refreshForms = () => {
    fetchFormsList(hash, verifyId)
      .then((response) => {
        if (response.status === 200) {
          setFormsList(response.data.$values || []); // Gelen veriyi doğru yapıya dönüştür
        } else {
          setError("Formlar listesi alınamadı");
        }
      })
      .catch((error) => {
        console.error("Formlar listesi API hatası:", error);
        setError("Formlar listesi alınamadı");
      });
  };

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      {step === 1 && (
        <PhoneNumberInput
          hash={hash}
          onPhoneSubmit={handlePhoneSubmit}
          onSetRemainingTime={handleSetRemainingTime}
        />
      )}
      {step === 2 && (
        <SmsVerification
          hash={hash}
          phone={phone}
          onSmsSubmit={handleSmsSubmit}
          onBack={handleBack}
          initialRemainingTime={remainingTime}
        />
      )}
      {step === 3 && (
        <FormList formsList={formsList} setStep={setStep} phone={phone} />
      )}
      {step === 4 && (
        <Form
          hash={hash}
          verifyId={verifyId}
          setStep={setStep}
          refreshForms={refreshForms}
        />
      )}
    </Container>
  );
};

export default App;
